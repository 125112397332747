import React, { useEffect, useRef, useState } from "react"
import Link from "gatsby-link"
import "../css/style.css"
import { graphql, useStaticQuery } from "gatsby"
import styled from "@emotion/styled"
import { medias } from "../css/media"
import { colors } from "../css/colors"
import { IoMdClose, IoMdMenu, IoMdPerson } from "react-icons/io"
import { useProfile } from "../app/auth/auth.hooks"
import { useLocation } from "@reach/router"

export const Header = () => {
    const metaData = useStaticQuery(graphql`
        query {
            site {
                siteMetadata {
                    title
                }
            }
            contentfulHpWelcome {
                logo {
                    file {
                        url
                    }
                }
            }
        }
    `)
    const { account } = useProfile()
    const checkRef = useRef()
    const location = useLocation()
    const [lastLocation, setLastLocation] = useState(location)
    useEffect(() => {
        if (location !== lastLocation) {
            setLastLocation(location)
            checkRef.current.checked = false
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [location])

    return (
        <HeaderContainer key={"header"}>
            <HeaderLogoWrapper>
                <Link to="/">
                    <HeaderLogo src={metaData.contentfulHpWelcome.logo.file.url} alt="metaData.site.siteMetadata.title" />
                </Link>
            </HeaderLogoWrapper>

            <BurgerButton htmlFor="burger-state">
                <IoMdMenu size="2rem" />
            </BurgerButton>
            <input ref={checkRef} type="checkbox" id="burger-state" style={{ display: "none" }} />
            <HeaderNav>
                <BurgerCloseButton htmlFor="burger-state">
                    <IoMdClose size="2rem" />
                </BurgerCloseButton>
                <HeaderLink to="/about">À propos</HeaderLink>
                <HeaderLink to="/volunteers">Bénévoles</HeaderLink>
                <HeaderLink to="/associations">Associations</HeaderLink>
                <HeaderLink to="/app/search">Rechercher</HeaderLink>
                {!account ? (
                    <ConnectionLink to="/app/login">
                        <IconContainer>
                            <IoMdPerson color={colors.action} size="1em" />
                        </IconContainer>
                        <span>Connexion</span>
                    </ConnectionLink>
                ) : (
                    <ConnectionLink to="/app">
                        <IconContainer>
                            <IoMdPerson color={colors.action} size="1em" />
                        </IconContainer>
                        <span>{account.name}</span>
                    </ConnectionLink>
                )}
            </HeaderNav>
        </HeaderContainer>
    )
}

const HeaderContainer = styled.header`
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    z-index: 20;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
    padding: 0 2rem;
`

const HeaderLink = styled(Link)`
    text-decoration: none;
    color: ${colors.lightText};
    font-size: 2rem;
    line-height: 1;
    box-sizing: border-box;
    margin: 0.1rem;
    &,
    & > * {
        vertical-align: middle;
    }

    &:hover {
        color: ${colors.action};
    }

    @media (${medias.tablet}) {
        font-size: 1rem;
        margin-left: 1.5rem;
    }
`

const ConnectionLink = styled(HeaderLink)`
    position: relative;
    padding: 0 3rem;

    @media (${medias.tablet}) {
        padding: 0 1.5rem;
        margin-left: 2.5rem;
    }
`

const IconContainer = styled.div`
    position: absolute;
    left: 0;
    font-size: 2.5rem;

    @media (${medias.tablet}) {
        font-size: 1.3rem;
    }
`

const HeaderNav = styled.nav`
    flex: 0 0 auto;

    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    align-items: center;

    position: fixed;
    top: 0;
    left: 0;
    bottom: 0;
    width: 100vw;
    transition: transform ease-in-out 150ms;
    background-color: ${colors.navBackground};

    #burger-state ~ & {
        transform: translateX(-100vw);
    }
    #burger-state:checked ~ & {
        transform: translateX(0);
    }

    @media (${medias.tablet}) {
        position: static;
        display: flex;
        transform: translateX(0) !important;
        margin-left: auto;
        flex-direction: row;
        background-color: transparent;
        width: auto;
    }
`

const HeaderLogoWrapper = styled.div`
    flex: 0 0 auto;
`

const HeaderLogo = styled.img`
    height: 2rem;
    width: auto;
    padding-top: 0.5rem;

    @media (${medias.tablet}) {
        height: 4rem;
        padding-top: 1rem;
    }
    @media (${medias.desktop}) {
        height: 5rem;
        padding-top: 1.5rem;
    }
`

const BurgerButton = styled.label`
    display: block;
    cursor: pointer;
    height: 2rem;
    width: 2rem;
    margin-left: auto;
    color: ${colors.lightText};
    &:hover {
        color: ${colors.action};
    }

    @media (${medias.tablet}) {
        display: none;
    }
`
const BurgerCloseButton = styled(BurgerButton)`
    position: absolute;
    top: 0;
    right: 0;
`
