import React from "react"
import Footer from "./Footer"
import { Header } from "./header"
import "../css/style.css"
import { AuthProvider } from "../app/auth/auth.hooks"

const Layout = (props) => (
    <AuthProvider>
        <Header key={"harderRoot"} />
        <main>{props.children}</main>
        <Footer />
    </AuthProvider>
)

export default Layout
