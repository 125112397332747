import React from "react"
import styled from "@emotion/styled"

export const SectionTitle = ({ title, children }) => {
    return <Title> {title || children} </Title>
}

const Title = styled.h1`
    font-size: 1rem;
    text-transform: uppercase;
    &::before {
        display: inline-block;
        content: "";
        border-top: 0.2rem solid #e66f47;
        width: 2rem;
    }
    &::after {
        display: inline-block;
        content: "";
        border-top: 0.2rem solid #e66f47;
        width: 5rem;
    }
`
