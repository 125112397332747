export const colors = {
    lightText: "#FFF",
    action: "#D0491C",
    navBackground: "#162E3E",
    darkText: "#4b4b4b",
    alert: "#bb0000",
    success: "#32CD32",
    volunteer: "#3193d1",
    association: "#ff7714"
}
