import React, { createContext, useContext, useEffect, useState } from "react"
import { auth, firestore, storage } from "../../firebase"
import { isBack } from "../../isBack"

const AuthContext = createContext({
    user: null,
    loggedIn: false
})
export const AuthProvider = ({ children }) => {
    const [user, setUser] = useState(auth?.currentUser)

    const [account, setAccount] = useState(null)

    useEffect(() => {
        if (isBack) return

        auth.onAuthStateChanged((user) => {
            if (!user) {
                setAccount(null)
                setUser(null)
                return
            }
            firestore
                .collection("users")
                .doc(user.uid)
                .get()
                .then((r) => {
                    setAccount(r.data())
                    setUser(user)
                })
        })

        const href = window.location.href

        if (auth.isSignInWithEmailLink(href)) {
            const email = new URL(href).searchParams.get("email")

            auth.signInWithEmailLink(email, href)
        }

        auth.getRedirectResult()
    }, [])

    return (
        <AuthContext.Provider
            value={{
                user,
                account,
                loggedIn: !!user
            }}
        >
            {children}
        </AuthContext.Provider>
    )
}

export const useProfile = () => {
    const { user, loggedIn, account } = useContext(AuthContext)

    return {
        user,
        account,
        loggedIn,
        updateUserField,
        login,
        signup,
        logout,
        sendEmailLink,
        forgotPassword,
        resetPassword
    }
}

async function updateUserField(field, value) {
    const user = auth.currentUser

    console.log(user, field, value)
    if (!user) return value
    const userRef = firestore.collection("users").doc(user.uid)

    switch (field) {
        case "name":
            await userRef.update({
                name: value
            })
            await user.updateProfile({
                displayName: value
            })
            break
        case "email":
            await user.updateEmail(value)
            break
        case "photoURL":
            const ref = storage.ref("users/" + user.uid)
            await ref.put(value)
            const url = await ref.getDownloadURL()
            await userRef.update({
                photoURL: url
            })
            await user.updateProfile({
                photoURL: url
            })
            return url
        default:
            await userRef.update({
                [field]: value
            })
            break
    }
    console.log(user)
    return value
}

async function sendEmailLink(email) {
    if (isBack) return
    const url = new URL(window.location.href)
    url.searchParams.append("email", email)
    await auth.sendSignInLinkToEmail(email, {
        url: url.href
    })
}

async function forgotPassword(email) {
    if (isBack) return
    await auth.sendPasswordResetEmail(email, {
        url: window.location.href.replace("/forgot", "")
    })
}

async function resetPassword(password) {
    if (isBack) return
    const url = new URL(window.location.href)
    const code = url.searchParams.get("code")
    await auth.confirmPasswordReset(code, password)
}

async function logout() {
    if (isBack) return
    await auth.signOut()
}

async function signup(email, password, userInfo = null) {
    if (isBack) return
    const credentials = await auth.createUserWithEmailAndPassword(email, password)
    try {
        auth.currentUser && auth.currentUser.sendEmailVerification().catch((e) => console.warn("failed to send verification email", e))
    } catch (e) {
        console.warn("failed to send verification email", e)
    }

    if (userInfo) {
        await firestore.collection("users").doc(credentials.user.uid).set(userInfo)
    }
}

async function login(email, password) {
    if (isBack) return
    await auth.signInWithEmailAndPassword(email, password)
}
