import React from "react"
import { graphql, Link, useStaticQuery } from "gatsby"
import styled from "@emotion/styled"
import { FooterColumn } from "./FooterColumn"
import { IoIosArrowDropupCircle, IoLogoFacebook, IoLogoLinkedin, IoLogoSlack } from "react-icons/io"
import { isBack } from "../isBack"

const Footer = () => {
    //TODO: Exporter la query ?
    const imageData = useStaticQuery(graphql`
        query {
            contentfulFooterLogo {
                logo {
                    file {
                        url
                    }
                }
            }
        }
    `)

    //Todo: slack icon ??
    return (
        <FooterComponent>
            <ContentLogo src={imageData.contentfulFooterLogo.logo.file.url} alt="imageData.contentfulHpAbout.logo.file.url" />

            <SingleFooterLink to="/contact">CONTACT</SingleFooterLink>
            <FooterColumn links={firstlinksData} />
            <FooterColumn links={secondlinksData} />
            <div>
                <a href="https://www.facebook.com/teachingboost/">
                    <IoLogoFacebook size="1.6rem" color="white" />
                </a>
                <a href="https://www.linkedin.com/company/teaching-boost/">
                    <IoLogoLinkedin size="1.6rem" color="white" />
                </a>
                <a href="https://join.slack.com/t/teachingboost/shared_invite/zt-i2shuuhe-w~hDFPXu2wSvdSx7mWij_Q">
                    <IoLogoSlack size="1.6rem" color="white" />
                </a>
            </div>

            <TopPageLink
                onClick={(event) => {
                    event.preventDefault()
                    if (isBack) return
                    window.scroll(0, 0)
                }}
            >
                <IoIosArrowDropupCircle size="1.38rem" color="#184e71" />
            </TopPageLink>
        </FooterComponent>
    )
}

const FooterComponent = styled.footer`
    padding-top: 1.8rem;
    background-color: #5295c1;
    width: 100%;
    height: 7rem;
    display: flex;
    justify-content: space-evenly;
    align-items: flex-start;
    & > IoIosArrowDropupCircle {
        margin-bottom: auto;
        cursor: pointer;
    }
`

const ContentLogo = styled.img`
    height: 5.3rem;
`

//Todo: fixing the height to fit the text ??
const SingleFooterLink = styled(Link)`
    color: white;
    font-weight: bold;
    text-decoration: none;
    font-size: 0.68rem;
`

const TopPageLink = styled.div`
    height: 1.38rem;
    position: fixed;
    right: 1rem;
    bottom: 1rem;
    cursor: pointer;
`

const firstlinksData = []

const secondlinksData = [
    {
        name: "MENTIONS LÉGALES",
        link: "/legalnotice"
    }
]

export default Footer
