import React from "react"
import styled from "@emotion/styled"
import { Link } from "gatsby"

export const FooterColumn = ({ links }) => {
    return (
        <Column>
            {links.map((element, i) => (
                <FooterLink key={`FooterLink-${i}`} to={element.link}>
                    {" "}
                    {element.name}{" "}
                </FooterLink>
            ))}
        </Column>
    )
}

const Column = styled.div`
    display: flex;
    flex-direction: column;
`

const FooterLink = styled(Link)`
    text-decoration: none;
    color: white;
    font-weight: bold;
    margin-bottom: 1.6rem;
    font-size: 0.68rem;
`
