import firebase from "@firebase/app"
import "@firebase/firestore"
import "@firebase/auth"
import "@firebase/storage"
import { isBack } from "./isBack"
import { isFrontDev } from "./isFrontDev"

export const firebaseConfig = isFrontDev
    ? {
          apiKey: "AIzaSyDaaFzYrEUN278W7rlpjxNZwNN6cXdK3vE",
          authDomain: "teachingboost-test.firebaseapp.com",
          databaseURL: "https://teachingboost-test.firebaseio.com",
          projectId: "teachingboost-test",
          storageBucket: "teachingboost-test.appspot.com",
          messagingSenderId: "530216415355",
          appId: "1:530216415355:web:3c253eef5409f68bf772de"
      }
    : {
          apiKey: "AIzaSyC6fX-3RDe-2GdZDwcqDgG1EoIVqZLo4X0",
          authDomain: "teachingboost-dev.firebaseapp.com",
          databaseURL: "https://teachingboost-dev.firebaseio.com",
          projectId: "teachingboost-dev",
          storageBucket: "teachingboost-dev.appspot.com",
          messagingSenderId: "612880315506",
          appId: "1:612880315506:web:987abe1e7599ffaceefeeb"
      }
export const firebaseApp = isBack ? {} : firebase.initializeApp(firebaseConfig)

export const auth = isBack ? {} : firebaseApp.auth()
export const firestore = isBack ? {} : firebaseApp.firestore()
export const storage = isBack ? {} : firebaseApp.storage()

export function queryResultToArray(queryResult) {
    return queryResult.docs.map((snap) => ({
        ...snap.data(),
        id: snap.id
    }))
}
